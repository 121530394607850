@layer components {
  .footer {
    @apply tw-bg-blue-950 tw-py-[50px] tw-mt-[80px] lg:tw-mt-[100px] tw-leading-6;

    &__inner {
      @apply tw-flex tw-justify-between tw-text-sm;
    }

    &__socials {
      @apply tw-flex tw-items-center;
    }

    &__heading {
      @apply tw-font-bold tw-text-blue-500 tw-pb-4;
    }

    &__copyright {
      @apply tw-text-md tw-mt-8 tw-text-neutral-400;
    }

    /* Menu */

    &__menu {
      @apply tw-hidden lg:tw-flex tw-flex-col tw-flex-wrap tw-max-w-md tw-w-full tw-ml-10 tw-pl-0 lg:tw-pl-5;
    }

    &__menu-grid {
      @apply tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-x-10 tw-w-full;
      grid-template-rows: auto 1fr;
    }

    &__menu-area-1 {
      grid-area: 1 / 1 / 2 / 4;
    }

    &__menu-area-2 {
      grid-area: 2 / 1 / 3 / 2;
    }

    &__menu-area-3 {
      grid-area: 2 / 2 / 3 / 3;
    }

    &__menu-area-4 {
      grid-area: 2 / 3 / 3 / 4;
    }

    &__menu-item {
      @apply tw-mb-2;
    }

    &__menu-link {
      @apply tw-text-white tw-transition tw-duration-200 tw-ease-in-out hover:tw-text-blue-400;
    }
  }
}