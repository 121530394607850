@layer components {
  .service-info {
    @apply tw-mb-[50px] lg:tw-mb-[156px];

    &__inner {
      @apply tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-p-4 tw-pb-6 lg:tw-p-8 lg:tw-pb-10 tw-bg-orange-50 tw-rounded-2xl lg:tw-rounded-3xl tw-flex tw-justify-between;
    }

    &__content {
      @apply tw-text-base tw-text-neutral-900 tw-leading-snug tw-max-w-[658px];

      > p {
        @apply tw-mb-4;

        &:last-of-type {
          @apply tw-mb-0;
        }
      }
    }

    &__content-title {
      @apply tw-mb-6;
    }

    &__content-link {
      @apply tw-text-[22px] tw-mt-8;
    }

    &__picture {
      @apply tw-flex tw-justify-end lg:tw-justify-center tw-items-center lg:tw-p-10 tw-flex-1;
    }
  }
}