@layer components {
  .index-services {
    @apply tw-mb-[64px] lg:tw-mb-[156px];

    &__list {
      @apply tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-2 lg:tw-gap-4;
    }

    &__list-item {
      @apply tw-flex tw-bg-orange-50 tw-pl-[44px] lg:tw-pl-[64px] tw-pr-10 tw-h-[44px] lg:tw-h-[72px] tw-items-center tw-text-md/[26px] lg:tw-text-base/[28px] lg:tw-font-bold tw-text-neutral-900 hover:tw-text-orange-500 tw-transition-all tw-relative tw-ease-in-out tw-bg-no-repeat tw-rounded-2xl lg:tw-rounded-3xl;
      background-size: 24px;
      background-position: left 24px center;

      @media (max-width: 1023px) {
        background-size: 20px;
        background-position: left 16px center;
      }

      &--file {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-file.svg');
      }

      &--coins {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-coins.svg');
      }

      &--search {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-search.svg');
      }

      &--globe {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-globe.svg');
      }

      &--phone {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-phone.svg');
      }

      &--letter {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-mail.svg');
      }

      &--arrow-up {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-arrow-up.svg');
      }

      &--chart {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-chart.svg');
      }

      &--coins-stacked {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-coins-stacked.svg');
      }

      &--flag {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-flag.svg');
      }

      &--globe-pin {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-globe-pin.svg');
      }

      &--growth {
        background-image: url('@/tfx.tax/styles/images/index-services/icon-growth.svg');
      }

      &:after {
        @apply tw-block tw-right-6 tw-top-[50%] tw-translate-y-[-50%] tw-absolute tw-w-6 tw-h-6 lg:tw-hidden lg:tw-right-8;
        content: url('@/tfx.tax/styles/images/icons/arrow-orange-right.svg');
      }

      &:hover {
        &:after {
          @apply tw-block tw-right-6;
        }
      }

      &--small-bussiness {
        @apply tw-h-auto tw-font-bold lg:tw-h-full tw-flex-col tw-py-6 tw-items-start tw-text-neutral-900 hover:tw-text-neutral-900;
        background-position: left 24px top 28px;

        &:after {
          display: none;
        }

        &:hover {
          &:after {
            display: none;
          }
        }

        @media (max-width: 1023px) {
          background-size: 20px;
          background-position: left 16px top 28px;
        }
      }
    }

    &__list-item-text {
      @apply tw-font-normal tw-text-md/[22px] lg:tw-text-[18px]/[26px] tw-mt-1;
    }
  }
}
