@layer components {
  .index-quote {
    @apply tw-mb-[60px];

    .tw-container {
      @media (max-width: 767px) {
        @apply tw-p-0;
      }
    }

    &__inner {
      @apply lg:tw-rounded-3xl tw-relative tw-pb-[300px] tw-pb-0 tw-bg-[#121515] lg:tw-bg-transparent;
    }

    &__picture {
      @apply lg:tw-rounded-3xl tw-min-h-[400px] lg:tw-min-h-[unset] tw-object-cover tw-w-full tw-object-right-top tw-aspect-auto;
    }

    &__text {
      @apply tw-bg-white/20 tw-rounded-2xl lg:tw-rounded-3xl tw-absolute tw-top-[34%] lg:tw-top-[60px] tw-left-4 tw-right-4 lg:tw-left-[152px] tw-p-4 lg:tw-p-6 tw-text-white tw-text-sm lg:tw-text-[15px] tw-leading-snug lg:tw-leading-6 lg:tw-max-w-[390px];
    }

    &__text-bottom {
      @apply tw-mt-4 tw-flex tw-items-center;
    }

    &__formula {
      @apply tw-flex tw-flex-wrap tw-flex-col lg:tw-flex-row tw-items-center tw-relative tw-px-4 tw-pb-10 tw-pt-[55px] lg:tw-p-0 lg:tw-absolute lg:tw-left-8 lg:tw-right-8 lg:tw-bottom-9;
    }

    &__formula-value {
      @apply tw-justify-center tw-bg-white/90 tw-flex-1 tw-flex tw-px-5 tw-min-h-[48px] tw-max-h-[48px] lg:tw-max-h-[unset] lg:tw-min-h-[unset] tw-h-[60px] tw-items-center tw-text-md lg:tw-text-[22px] tw-text-neutral-900 tw-bg-no-repeat tw-rounded-[100px] tw-w-full lg:tw-w-auto;
    }

    &__formula-icon {
      @apply tw-mr-2 lg:tw-mr-4 tw-w-[19px] lg:tw-w-6 tw-h-[19px] lg:tw-h-6;
      background-size: 100%;
      background-position: center;

      &--file {
        background-image: url('@/tfx.tax/styles/images/index-quote/icon-file.svg');
      }

      &--presentation {
        background-image: url('@/tfx.tax/styles/images/index-quote/icon-presentation.svg');
      }

      &--stars {
        background-image: url('@/tfx.tax/styles/images/index-quote/icon-stars.svg');
      }
    }

    &__formula-sign {
      @apply tw-px-5 tw-py-3 tw-text-white tw-text-[32px] tw-font-bold;
    }
  }
}