@layer components {
  .service-text {
    @apply tw-mb-10;

    &__client-count {
      @apply tw-inline-block tw-px-7 tw-py-2 tw-leading-none tw-rounded-[125px] tw-text-[22px] tw-text-blue-500 tw-bg-blue-100 tw-font-bold;
    }

    &__content {
      @apply tw-max-w-[890px] tw-leading-snug tw-text-base tw-mb-6;
    }
  }
}