@layer components {
  .who-we-help {
    @apply tw-mb-[64px] lg:tw-mb-[156px];

    &__inner {
      @apply tw-flex;
    }

    &__picture {
      @apply tw-w-full lg:tw-max-w-[370px] tw-hidden lg:tw-block tw-mr-8;
    }

    &__img {
      @apply tw-object-cover tw-object-center tw-rounded-2xl lg:tw-rounded-3xl tw-h-full;
    }

    /* Tabs */

    &__tabs {
      @apply tw-bg-blue-50 tw-w-full tw-rounded-2xl lg:tw-rounded-3xl tw-p-4 tw-pb-6 lg:tw-py-8 lg:tw-px-10 tw-text-neutral-900;
    }

    &__tabs-title {
      @apply tw-mb-6 lg:tw-mb-8;
    }

    &__tabs-text {
      @apply tw-mb-10 tw-text-md/6 lg:tw-text-base/9;
    }

    &__tabs-btns {
      @apply tw-flex tw-flex-wrap tw-mb-6 lg:tw-mb-8;
    }

    &__tabs-item {
      @apply tw-bg-blue-200 tw-text-blue-500 tw-font-bold tw-mr-2 lg:tw-mr-3 tw-mb-2 tw-py-3 tw-px-5 tw-cursor-pointer tw-whitespace-nowrap tw-rounded-[100px] tw-transition tw-duration-200 tw-ease-in-out tw-text-sm lg:tw-text-md;

      &--active {
        @apply tw-cursor-default tw-bg-blue-500 tw-text-white;
      }
    }

    &__tabs-content {
      @apply tw-text-md/6 lg:tw-text-base/9 tw-text-neutral-900;

      > p {
        @apply tw-mb-4;

        &:last-of-type {
          @apply tw-mb-0;
        }
      }
    }

    &__list {
      @apply tw-list-decimal tw-text-md/6 lg:tw-text-[20px]/[32px] tw-ml-5;
    }
  }
}
