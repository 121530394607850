@layer components {
  .index-advantages {
    @apply tw-mb-[64px] lg:tw-mb-[156px];

    &__list {
      @apply tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4 lg:tw-gap-8;
    }

    &__item-icon {
      @apply tw-h-6 tw-mb-2 tw-w-full tw-bg-no-repeat tw-bg-left tw-bg-auto;
    }

    &__item {
      @apply tw-bg-blue-50 tw-w-full tw-rounded-2xl lg:tw-rounded-3xl tw-p-4 tw-bg-no-repeat tw-bg-right-bottom tw-bg-25 lg:tw-bg-auto  tw-p-4 tw-pb-[96px] lg:tw-px-8 lg:tw-py-10 lg:tw-pr-[165px];

      &--zero-effort {
        background-image: url('@/tfx.tax/styles/images/index-advantages/bg-zap.svg');

        .index-advantages__item-icon {
          background-image: url('@/tfx.tax/styles/images/index-advantages/icon-zap.svg');
        }
      }

      &--client-returns {
        background-image: url('@/tfx.tax/styles/images/index-advantages/bg-heart.svg');

        .index-advantages__item-icon {
          background-image: url('@/tfx.tax/styles/images/index-advantages/icon-heart.svg');
        }
      }

      &--accuracy {
        background-image: url('@/tfx.tax/styles/images/index-advantages/bg-check.svg');

        .index-advantages__item-icon {
          background-image: url('@/tfx.tax/styles/images/index-advantages/icon-check.svg');
        }
      }

      &--experts {
        background-image: url('@/tfx.tax/styles/images/index-advantages/bg-users.svg');

        .index-advantages__item-icon {
          background-image: url('@/tfx.tax/styles/images/index-advantages/icon-users.svg');
        }
      }
    }

    &__item-title {
      @apply tw-mb-2;
    }

    &__item-text {
      @apply tw-text-md lg:tw-text-base tw-leading-snug;
    }
  }
}