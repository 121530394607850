@layer components {
  .about-us-header {
    &__inner {
      @apply tw-pt-6 tw-pb-[64px] lg:tw-py-[64px] lg:tw-py-[96px] tw-max-w-sm tw-mx-auto tw-text-sm lg:tw-text-[18px] tw-leading-snug lg:tw-leading-8 tw-text-left;
    }

    &__title {
      @apply tw-mb-4 lg:tw-mb-6 tw-text-left lg:tw-text-center;
    }
  }
}