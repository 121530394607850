@font-face {
  font-family: 'archivobold';
  src: url('~tfx.tax/styles/fonts/archivo-bold-webfont.woff') format('woff'),
  url('~tfx.tax/styles/fonts/archivo-bold-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'archivomedium';
  src: url('~tfx.tax/styles/fonts/archivo-medium-webfont.woff') format('woff'),
  url('~tfx.tax/styles/fonts/archivo-medium-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'archivoitalic';
  src: url('~tfx.tax/styles/fonts/archivo-italic-webfont.woff') format('woff'),
  url('~tfx.tax/styles/fonts/archivo-italic-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'archivomedium';
}