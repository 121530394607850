@layer components {
  .video-slider {
    &__wrapper {
      @apply tw-mb-[64px] lg:tw-mb-[156px];
    }

    &__header {
      @apply tw-flex tw-justify-between tw-items-end tw-mb-4 lg:tw-mb-10;
    }

    &__title {
      @apply tw-text-[22px] lg:tw-text-2xl tw-font-bold tw-text-neutral-900;
    }

    &__subtitle {
      @apply tw-max-w-[890px] tw-leading-snug tw-text-base tw-mt-2 tw-font-normal tw-text-left;
    }

    &__controls {
      @apply tw-flex;
    }

    &__prev, &__next {
      @apply tw-w-10 tw-h-10 tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer;
      background-image: url('@/tfx.tax/styles/images/icons/chevron-left.svg');
    }

    &__next {
      @apply tw-rotate-180 tw-ml-4;
    }

    &__slide {
      @apply tw-relative tw-overflow-hidden tw-rounded-2xl lg:tw-rounded-3xl tw-bg-white tw-cursor-pointer;
      @apply tw-mr-3 lg:tw-mr-4 #{!important};
    }

    &__slide-image {
      @apply tw-w-[192px] lg:tw-w-[232px] tw-h-[327px] lg:tw-h-[396px] tw-object-cover;
    }

    &__slide-text {
      @apply tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-p-4 tw-h-[148px] lg:tw-h-[180px] tw-flex tw-flex-col-reverse tw-text-neutral-0 tw-font-bold tw-text-[16px]/[24px] lg:tw-text-[20px]/[28px];
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.99) 0%, rgba(0, 0, 0, 0.00) 100%);
    }
  }

  .video-player-modal {
    @apply tw-fixed tw-inset-0 tw-w-full tw-h-full tw-table tw-z-50 tw-overflow-hidden tw-cursor-pointer;
    background-color: rgba(0, 0, 0, .5);

    &__inner {
      @apply tw-table-cell tw-text-center tw-align-middle tw-relative tw-px-5;
    }

    &__close {
      @apply tw-relative tw-w-4 tw-h-4 tw-top-[-10px] tw-mx-auto;
      background: url('@/static/images/icons/default/icon-close-white.svg') no-repeat 50% 50%;
    }

    &__iframe {
      @apply tw-max-w-[720px] tw-w-full tw-h-[420px] tw-border-0 tw-rounded-2xl lg:tw-rounded-3xl;
    }
  }
}
