@layer components {
  .text-slider {
    @apply tw-relative;

    &__wrapper {
      @apply tw-mb-[64px] lg:tw-mb-[156px];
    }

    &__controls {
      @apply tw-flex tw-absolute tw-bottom-full tw-right-4 tw-pb-10;
    }

    &__prev, &__next {
      @apply tw-w-10 tw-h-10 tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer;
      background-image: url('@/tfx.tax/styles/images/icons/chevron-left.svg');
    }

    &__next {
      @apply tw-rotate-180 tw-ml-4;
    }

    &__controls {
      @apply tw-flex tw-absolute tw-bottom-full tw-right-4 tw-pb-10;
    }

    &__prev, &__next {
      @apply tw-w-10 tw-h-10 tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer;
      background-image: url('@/tfx.tax/styles/images/icons/chevron-left.svg');
    }

    &__next {
      @apply tw-rotate-180 tw-ml-4;
    }

    &__slide {
      @apply tw-relative tw-rounded-2xl lg:tw-rounded-3xl tw-bg-orange-50 tw-w-full lg:tw-w-[calc(33.3%-16px)] tw-p-4 lg:tw-p-6 tw-pb-[72px] lg:tw-pb-[80px];
    }

    &__slide-title, &__slide-text, &__slide-author, &__slide-link {
      @apply tw-text-md/6 lg:tw-text-[15px]/[24px];
    }

    &__slide-title, &__slide-text, &__slide-author {
      @apply tw-text-neutral-900;
    }

    &__slide-title, &__slide-text {
      @apply tw-italic;
    }

    &__slide-title {
      @apply tw-font-bold;
    }

    &__slide-author {
      @apply tw-absolute tw-bottom-4 tw-left-4 lg:tw-bottom-8 lg:tw-left-6 tw-font-medium;
    }

    &__slide-link {
      @apply tw-text-orange-500 tw-font-medium tw-ml-6;
    }

    &__pagination {
      @apply tw-bottom-[-26px] #{!important};

      .splide__pagination__page {
        @apply tw-bg-neutral-200 tw-m-1;

        &.is-active {
          @apply tw-bg-neutral-800 tw-scale-100 #{!important};
        }
      }
    }
  }
}
