@layer components {
  .index-header {
    &__inner {
      @apply tw-py-[18px] lg:tw-py-[50px] tw-text-center tw-max-w-lg tw-mx-auto;
    }

    &__title {
      @apply tw-text-[22px] lg:tw-text-[50px] tw-leading-normal;
    }

    &__text {
      @apply tw-inline-block tw-px-6 tw-py-[2px] tw-leading-none tw-rounded-[125px];

      &--orange {
        @apply tw-text-orange-600 tw-bg-orange-100;
      }

      &--blue {
        @apply tw-text-blue-600 tw-bg-blue-100;
      }
    }

    &__experts {
      @apply tw-inline-block tw-mb-[-6px] lg:tw-mb-[-13px] tw-h-[26px] tw-w-auto lg:tw-h-[62px] lg:tw-w-[156px];
    }

    &__get-started {
      @apply tw-mx-auto;
    }

    &__get-started-wrapper {
      @apply tw-max-w-xs tw-mx-auto lg:tw-max-w-none lg:tw-flex lg:tw-justify-center tw-mt-8 lg:tw-mt-[68px] tw-mb-9;

      .btn-lg {
        @apply tw-w-full lg:tw-w-auto;
      }
    }

    &__reviews {
      @apply tw-flex tw-mt-6 lg:tw-mt-0 tw-flex-col lg:tw-ml-8 tw-justify-center tw-items-center lg:tw-items-start;
    }

    &__reviews-count {
      @apply tw-flex tw-items-center tw-mb-[14px];
    }

    &__reviews-stars {
      @apply tw-text-md tw-tracking-[3px] tw-text-amber-400;
    }

    &__reviews-text {
      @apply tw-text-[18px] tw-font-bold tw-ml-2;
    }

    &__social-reviews {
      @apply tw-flex tw-w-full tw-items-center;
    }
  }
}