@layer components {
  .prices {
    @apply tw-mb-[64px] lg:tw-mb-[156px] tw-mt-6 lg:tw-mt-[56px];

    &__inner {
      @apply tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4 lg:tw-gap-8;
    }

    &__text {
      @apply tw-text-neutral-900 tw-text-md lg:tw-text-base tw-leading-snug lg:tw-leading-9 tw-mb-4 lg:tw-mb-8;
    }

    &__item {
      @apply tw-bg-blue-50 tw-w-full tw-rounded-2xl lg:tw-rounded-3xl tw-p-4 tw-pb-6 lg:tw-px-10 lg:tw-pt-10 lg:tw-pb-[48px] tw-text-neutral-900 tw-leading-snug;

    }

    &__item-title {
      @apply tw-mb-2 lg:tw-mb-4;

    }

    &__item-text {
      @apply tw-text-[18px] tw-mb-4 lg:tw-mb-6;
    }

    &__item-list {
      @apply tw-list-disc tw-ml-5;

      > li {
        @apply tw-mb-1 tw-text-[18px];
      }
    }

    &__item-price {
      @apply tw-text-[40px] lg:tw-text-[48px] tw-mb-4 lg:tw-mb-6 tw-font-bold tw-text-blue-500;
    }

    &__item-link {
      @apply tw-text-[20px] tw-mb-8;
    }

    &__get-in-touch {
      @apply tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-font-bold tw-text-base tw-pt-[48px] tw-pb-6 lg:tw-py-6 tw-px-4 lg:tw-px-10 lg:tw-pl-[80px] tw-rounded-2xl lg:tw-rounded-3xl tw-bg-orange-50 tw-mt-8 tw-bg-no-repeat;
      background-position: left 40px top 24px;
      background-size: 24px;
      background-image: url('@/tfx.tax/styles/images/icons/icon-coins.svg');

      @media (max-width: 1023px) {
        background-position: left 16px top 16px;
      }
    }
  }
}
