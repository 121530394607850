@layer components {
  .service-consultation {
    @apply tw-container;

    &__container {
      @apply tw-rounded-2xl lg:tw-rounded-3xl tw-p-4 lg:tw-p-[40px] tw-bg-blue-50 tw-mb-[150px];
    }

    &__tabs-btns {
      @apply tw-flex tw-flex-col lg:tw-flex-row tw-items-start tw-mb-6 lg:tw-mb-10;
    }

    &__tabs-item {
      @apply tw-bg-blue-200 tw-text-blue-500 tw-font-bold tw-mr-2 lg:tw-mr-3 tw-mb-2 tw-py-3 tw-px-5 tw-cursor-pointer tw-whitespace-nowrap tw-rounded-[100px] tw-transition tw-duration-200 tw-ease-in-out;

      &--active {
        @apply tw-cursor-default tw-bg-blue-500 tw-text-white;
      }
    }

    &__step {
      @apply tw-text-[20px]/[32px] tw-text-neutral-900 tw-mb-4;
    }

    &__login-btn {
      @apply tw-block tw-max-w-[300px] btn btn-md btn-orange-border tw-text-center;
    }
  }
}