@layer components {
  .partners {
    @apply tw-mb-10 lg:tw-mb-[156px];

    &__title {
      @apply tw-text-[18px] tw-text-neutral-900;
    }

    &__list {
      @apply tw-py-6 tw-flex tw-items-center tw-w-full lg:tw-justify-between tw-flex-wrap;
    }

    &__img {
      @apply tw-block tw-h-auto tw-mr-8 tw-mb-6 lg:tw-mb-0 lg:tw-m-auto tw-max-w-full;
    }
  }
}