@layer components {
  .link {
    @apply tw-transition tw-duration-200 tw-ease-in-out  tw-inline-flex tw-items-center tw-relative;

    &__blue {
      @apply tw-text-blue-500 hover:tw-text-blue-700;
    }

    &__orange {
      @apply tw-text-orange-500 hover:tw-text-orange-700;
    }

    &--arrow {
      @apply tw-pr-6 tw-bg-center tw-bg-no-repeat;

      &:after {
        @apply tw-absolute tw-transition-all tw-duration-200 tw-ease-in-out;
      }

      &.link__blue {
        &:after {
          @apply tw-w-[18px] tw-h-[18px] tw-right-1;
          content: url('@/tfx.tax/styles/images/icons/arrow-right.svg');
        }
      }

      &.link__orange {
        @apply tw-pr-7;

        &:after {
          @apply tw-w-6 tw-h-6 tw-right-1;
          content: url('@/tfx.tax/styles/images/icons/arrow-orange-right.svg');
        }
      }

      &:hover {
        &:after {
          @apply tw-right-0;
        }

        &.link__orange {
          &:after {
            content: url('@/tfx.tax/styles/images/icons/arrow-orange-right-dark.svg');
          }
        }

        &.link__blue {
          &:after {
            content: url('@/tfx.tax/styles/images/icons/arrow-right.svg');
          }
        }
      }
    }

    &--arrow-left {
      @apply tw-pl-6 tw-bg-center tw-bg-no-repeat;

      &:after {
        @apply tw-absolute tw-transition-all tw-duration-200 tw-ease-in-out;
      }

      &.link__orange-small {
        @apply tw-pl-7;

        &:after {
          @apply tw-w-6 tw-h-6 tw-left-0 lg:tw-left-1 tw-top-[-5px];
          content: url('@/tfx.tax/styles/images/icons/arrow-orange-small.svg');
          transform: rotate(180deg);
        }
      }

      &:hover {
        @apply tw-pl-7;

        &.link__orange-small {
          &:after {
            content: url('@/tfx.tax/styles/images/icons/arrow-orange-small-dark.svg');
          }
        }
      }
    }
  }
}
