/* Forms */
@layer components {
  .form-input,
  .form-textarea,
  .form-multiselect,
  .form-select,
  .form-checkbox,
  .form-radio {
    @apply tw-w-full tw-border tw-border-neutral-400 tw-border-solid tw-text-[16px]/[24px] tw-text-neutral-900 tw-font-medium tw-mb-1;
  }

  .form-input,
  .form-textarea,
  .form-multiselect,
  .form-select,
  .form-checkbox {
    @apply tw-rounded;
  }

  .form-input,
  .form-textarea,
  .form-multiselect,
  .form-select {
    @apply tw-py-3 tw-px-4 lg:tw-py-5;
  }

  .form-input,
  .form-textarea {
    @apply tw-placeholder-gray-500;
  }

  .form-select {
    @apply tw-pr-10;
  }

  .form-checkbox,
  .form-radio {
    @apply tw-text-gray-800 tw-rounded-sm;
  }

  .form-group {
    @apply tw-mb-5 lg:tw-mb-7 tw-text-rose-600;

    &--with-error {
      @apply tw-text-rose-600;
    }

    &__error {
      @apply tw-text-rose-600;
    }

    &__label {
      @apply tw-text-neutral-700 tw-font-medium tw-mb-1 tw-text-[14px]/[20px] lg:tw-text-[16px]/[22px];
    }
  }
}