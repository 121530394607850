@layer components {
  .contact-us {
    @apply tw-flex tw-flex-col lg:tw-flex-row;

    &__header {
      @apply tw-text-neutral-900 tw-mb-4 tw-mt-6 lg:tw-my-10;
    }

    &__form {
      @apply tw-flex tw-flex-col lg:tw-flex-1 tw-mb-2 lg:tw-mb-8;
    }

    &__form-send {
      @apply tw-w-full lg:tw-w-auto lg:tw-ml-auto;
    }

    &__info {
      @apply lg:tw-w-[300px] lg:tw-ml-[64px];
    }

    &__whatsapp {
      @apply tw-flex tw-items-center tw-justify-center tw-w-full tw-text-center tw-p-2 lg:tw-p-3 tw-border tw-border-solid tw-border-green-600 tw-rounded tw-text-[14px]/[22px] lg:tw-text-[22px]/[40px] tw-text-green-600 tw-font-bold tw-mb-10 lg:tw-mt-[26px] lg:tw-mb-8;
    }

    &__whatsapp-icon {
      @apply tw-w-4 lg:tw-w-[22px] tw-h-4 lg:tw-h-[22px] tw-mr-2 lg:tw-mr-[6px] tw-inline-block tw-bg-cover;
      background-image: url('@tfx/tfx.tax/images/icons/icon-whatsapp-green.svg');
    }

    &__contact-type {
      @apply tw-text-[14px]/[22px] tw-text-neutral-900 tw-font-medium tw-mb-1;
    }

    &__contact-info {
      @apply tw-text-[18px]/[24px] tw-text-neutral-900 tw-font-bold tw-mb-4 lg:tw-mb-6;
    }

    &__form-footer {
      @apply tw-flex tw-justify-center tw-w-full tw-items-center tw-flex-col lg:tw-flex-row;
    }

    &__form-terms-text {
      @apply tw-text-sm/5 lg:tw-text-md/6 tw-mb-4 lg:tw-mb-0 lg:tw-mr-8 tw-text-neutral-500;
    }
  }
}
