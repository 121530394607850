@layer components {
  .index-blog {
    @apply tw-mb-[64px] lg:tw-mb-[156px];

    &__text {
      @apply tw-text-gray-600 tw-text-md lg:tw-text-xl tw-mb-8 lg:tw-mb-10;
    }

    &__list {
      @apply tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-8 lg:tw-gap-[70px];
    }

    &__item-title {
      @apply tw-mb-2 tw-line-clamp-2 tw-leading-7;
    }

    &__item-text {
      @apply tw-mb-4 tw-text-sm tw-line-clamp-4 tw-leading-5 tw-text-gray-700;
    }

    &__item-info {
      @apply tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-4;
    }

    &__item-date {
      @apply tw-text-neutral-400 tw-text-xs;
    }

    &__item {
      &--last {
        @apply tw-flex tw-justify-start lg:tw-justify-start tw-items-center;
      }
    }
  }
}