body {
  font-size: 16px;
  line-height: 1.2;
}

/* Typography */
@layer components {
  h1,
  .h1 {
    @apply tw-text-[26px] lg:tw-text-3xl tw-font-extrabold tw-leading-tight tw-tracking-tighter tw-text-neutral-900;
  }

  h2,
  .h2 {
    @apply tw-text-[22px] lg:tw-text-2xl tw-font-extrabold tw-text-neutral-900;
  }

  h3,
  .h3 {
    @apply tw-text-base lg:tw-text-xl tw-font-bold tw-leading-tight tw-text-neutral-900;
  }

  h4,
  .h4 {
    @apply tw-text-[18px] lg:tw-text-base tw-font-bold tw-leading-snug tw-tracking-tight tw-text-neutral-900;
  }

  h5,
  .h5 {
    @apply tw-text-md lg:tw-text-[18px] tw-font-bold tw-leading-snug tw-tracking-tight tw-text-neutral-900;
  }

  /* Layout */
  .body {
    @apply tw-antialiased tw-bg-white tw-text-gray-900;
  }
  .layout {
    @apply tw-flex tw-flex-col tw-min-h-screen;
  }
  .content {
    @apply tw-flex-grow tw-pt-[55px] lg:tw-pt-[75px];
  }
}

/* Content */
@layer components {
  .base-content {
    @apply tw-my-10 lg:tw-my-[85px];

    &__inner {
      @apply tw-max-w-base tw-mx-auto tw-text-neutral-900 tw-text-md lg:tw-text-base tw-leading-snug lg:tw-leading-9;
    }

    &__page-title {
      @apply tw-text-blue-500 tw-text-md lg:tw-text-[18px] tw-font-bold tw-mb-2 tw-text-center;
    }

    &__page-text {
      @apply tw-text-neutral-900 tw-text-md lg:tw-text-[18px] tw-mb-2 tw-text-center tw-mb-10 lg:tw-mb-[80px];
    }

    &__list {
      @apply tw-list-disc tw-ml-5 tw-mb-6;

      > li {
        @apply tw-mb-1;
      }
    }

    &__small-title {
      @apply tw-text-blue-500 tw-text-md lg:tw-text-base tw-font-bold tw-my-3;
    }

    &__row {
      @apply tw-mb-10 lg:tw-mb-[80px];

      > h2, .h2 {
        @apply tw-mb-3 lg:tw-mb-6 tw-text-center;
      }

      > p {
        @apply tw-mb-3 lg:tw-mb-6 tw-text-neutral-900 tw-text-md lg:tw-text-base tw-leading-snug lg:tw-leading-9;
      }

      .link {
        @apply tw-underline;
      }
    }
  }
}

@layer components {
  // youtube player
  .youtube-embed {
    @apply tw-relative tw-pb-[50%] tw-w-full tw-text-left tw-rounded-xl lg:tw-rounded-3xl tw-overflow-hidden;

    iframe {
      @apply tw-w-full tw-absolute tw-h-full tw-overflow-hidden;
    }
  }
}
