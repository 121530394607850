@layer components {
  .service-section {
    @apply tw-mb-[64px] lg:tw-mb-[156px];

    &__inner {
      @apply tw-flex tw-flex-col-reverse lg:tw-flex-row;
    }

    &__picture {
      @apply tw-h-[167px] lg:tw-h-auto tw-w-full lg:tw-max-w-[370px] tw-ml-0 tw-mb-4 lg:tw-mb-0 lg:tw-ml-8;
    }

    &__img {
      @apply tw-object-cover tw-object-top lg:tw-object-center tw-rounded-2xl lg:tw-rounded-3xl tw-h-full tw-max-h-[166px] lg:tw-max-h-full;
    }

    &__content {
      @apply tw-w-full tw-rounded-2xl lg:tw-rounded-3xl tw-p-4 tw-pb-6 lg:tw-px-10 lg:tw-pt-8 lg:tw-pb-[48px] tw-text-neutral-900;

      &--orange {
        @apply tw-bg-orange-50;
      }

      &--blue {
        @apply tw-bg-blue-50;
      }
    }

    &__content-title {
      @apply tw-mb-8 lg:tw-mb-6;
    }

    &__text {
      @apply tw-text-md/6 lg:tw-text-base/9 tw-mb-4;
    }

    &__title-included {
      @apply tw-font-bold tw-text-orange-500 tw-mb-2;
    }

    &__list {
      @apply tw-list-disc tw-ml-5 tw-mb-6 tw-text-md/6 lg:tw-text-base/9;

      > li {
        @apply tw-mb-1;
      }
    }

    &__info {
      @apply tw-font-bold tw-text-orange-500 tw-mb-9;
    }

    &__price {
      @apply tw-text-[40px] lg:tw-text-[48px];
    }

    &__btn {
      @apply lg:tw-max-w-[286px];
    }

    &__info-text {
      @apply tw-text-[15px] tw-font-normal;
    }

    &__price-list {
      @apply tw-flex tw-flex-col lg:tw-flex-row tw-pt-2 tw-mb-8;
    }

    &__price-list-title {
      @apply tw-text-[18px];
    }

    &__price-list-left-col {
      @apply lg:tw-mr-[60px];
    }

    &__price-list-right-col {
      @apply tw-mt-6 lg:tw-mt-0;
    }
  }
}
