@mixin reset-space() {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

html {
  -webkit-text-size-adjust: none;
  font-family: Arial, Helvetica, sans-serif;
}

body,
html {
  min-height: 100%;
  height: 100%;
  width: 100%;
  @include reset-space();
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 0;
}

ol, ul, nav {
  list-style: none;
  @include reset-space();
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  line-height: 1.17;
  letter-spacing: normal;
  @include reset-space();
}

p {
  @include reset-space();
}

a {text-decoration: none;}

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

textarea, input:not([type="checkbox"]) {
  -webkit-appearance: none;
}

svg {
  shape-rendering: geometricPrecision;
}

button {
  outline: none;
  background: transparent;
  border: none;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}