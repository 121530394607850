@layer components {
  .about-us-info {
    @apply tw-mb-[50px] lg:tw-mb-[156px];

    &__inner {
      @apply tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-p-4 lg:tw-px-10 lg:tw-py-6 tw-bg-blue-50 tw-rounded-2xl lg:tw-rounded-3xl tw-flex tw-justify-between;
    }

    &__content {
      @apply tw-text-md lg:tw-text-base tw-text-neutral-900 tw-leading-snug tw-max-w-[658px];

      > p {
        @apply tw-mb-4;

        &:last-of-type {
          @apply tw-mb-0;
        }
      }
    }

    &__content-title {
      @apply tw-mb-6;
    }

    &__picture {
      @apply tw-flex tw-justify-end lg:tw-justify-center tw-items-center tw-flex-1;
    }

    &__img {
      @apply tw-w-[96px] lg:tw-w-[260px]
    }
  }
}