@layer components {
  .security {
    @apply tw-mb-[64px] lg:tw-mb-[156px];

    &__inner {
      @apply tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4 lg:tw-gap-8;
    }

    &__item {
      @apply tw-min-h-[220px] md:tw-min-h-[320px] tw-bg-blue-50 tw-w-full tw-rounded-2xl lg:tw-rounded-3xl tw-p-4 lg:tw-p-8 tw-text-neutral-900 tw-pb-10 md:tw-pb-[220px] tw-bg-no-repeat tw-bg-auto tw-bg-right-bottom;

      &--access {
        background-position: right 45px bottom;
        background-image: url('@/tfx.tax/styles/images/2fa/phone.png');
        background-size: 32% auto;
      }

      &--2fa {
        background-image: url('@/tfx.tax/styles/images/2fa/two-factor.png');
        background-size: 85% auto;
      }
    }
  }
}
