/* Buttons */
@layer components {
  .btn,
  [class^=btn] {
    @apply tw-text-sm tw-inline-flex tw-items-center tw-justify-center tw-border tw-border-transparent tw-rounded tw-leading-none tw-transition tw-duration-150 tw-ease-in-out;

    &:hover,
    &:focus {
      @apply tw-cursor-pointer;
    }
  }

  .btn {
    @apply tw-px-4 tw-py-3;
  }

  /* Sizes */
  .btn-sm {
    @apply tw-px-[18px] tw-py-[14px];
  }

  .btn-md {
    @apply tw-py-3 lg:tw-py-4 tw-px-[18px] tw-text-sm lg:tw-text-md;
  }

  .btn-lg {
    @apply tw-px-5 tw-py-[11px] lg:tw-px-8 lg:tw-py-[18px] tw-text-sm/5 lg:tw-text-base/7;
  }

  /* Colors */
  .btn-transparent {
    @apply tw-font-normal tw-bg-transparent tw-text-orange-600;

    &:hover {
      @apply tw-text-orange-700;
    }
  }

  .btn-orange {
    @apply tw-text-white tw-font-bold tw-bg-orange-600 tw-border tw-border-solid;

    &:hover {
      @apply tw-bg-orange-700;
    }
  }

  .btn-orange-border {
    @apply tw-text-orange-600 tw-font-bold tw-bg-white tw-border tw-border-orange-600 tw-border-solid;

    &:hover {
      @apply tw-bg-orange-700 tw-text-white;
    }
  }

  .btn-blue {
    @apply tw-text-white tw-font-bold tw-bg-blue-600;

    &:hover {
      @apply tw-bg-blue-700;
    }
  }

  .btn-blue-border {
    @apply tw-text-blue-500 tw-font-bold tw-bg-white tw-border tw-border-blue-500 tw-border-solid;

    &:hover {
      @apply tw-bg-blue-700 tw-text-white;
    }
  }

  .btn-orange-border {
    @apply tw-text-orange-500 tw-font-bold tw-bg-white tw-border tw-border-orange-500 tw-border-solid;

    &:hover {
      @apply tw-bg-orange-600 tw-text-white;
    }
  }
}

