@layer components {
  .header {
    @apply tw-fixed tw-w-full tw-z-30 lg:tw-bg-opacity-90 tw-transition tw-duration-300 tw-ease-in-out tw-bg-white;

    &__inner {
      @apply tw-flex tw-items-center tw-justify-between tw-py-3 lg:tw-py-4;
    }

    &__logo {
      @apply tw-flex-shrink-0 tw-mr-6 xl:tw-mr-10;
    }

    &__actions {
      @apply lg:tw-flex tw-justify-end tw-flex-wrap tw-items-center;
    }

    &__actions-btns {
      @apply tw-flex tw-items-center;
    }

    &__actions-phones {
      @apply tw-hidden lg:tw-flex tw-items-center;
    }

    &__actions-auth {
      @apply tw-hidden lg:tw-flex;
    }

    &__menu-login {
      @apply tw-block lg:tw-hidden tw-mr-6;
    }

    &__phone {
      @apply tw-flex tw-text-neutral-900 tw-mr-4;
    }

    &__wa {
      @apply tw-block lg:tw-hidden xl:tw-block tw-px-4;
    }

    /* Menu */
    &__menu-mobile-btn {
      @apply tw-flex lg:tw-hidden;
    }

    &__menu-wrapper {
      @apply
      /* mobile menu view */
      tw-h-0 tw-w-full tw-absolute tw-top-full tw-bg-neutral-0 tw-left-0 tw-transition tw-transition-all tw-duration-300 tw-ease-in-out tw-overflow-hidden

      /* desktop menu view*/
      lg:tw-flex lg:tw-h-auto lg:tw-items-center lg:tw-w-auto lg:tw-relative lg:tw-h-auto lg:tw-overflow-visible lg:tw-bg-transparent;

      &.active {
        @apply tw-h-[calc(100dvh_-_59px)];
      }
    }

    &__menu-inner {
      @apply lg:tw-flex tw-items-baseline tw-overflow-auto lg:tw-overflow-visible;
    }

    &__menu-icon {
      @apply tw-fill-current tw-h-4 tw-w-4 tw-ml-2 tw-transform tw-transition-transform;

      &--rotate {
        transform: rotate(180deg);
      }
    }

    &__menu {
      @apply tw-max-w-md tw-mx-auto tw-h-full lg:tw-h-auto tw-flex-col tw-px-4 tw-py-6 lg:tw-p-0 tw-flex lg:tw-flex-row tw-justify-between;
    }

    &__menu-item {
      @apply
      tw-text-neutral-900 hover:tw-text-blue-500 hover:tw-cursor-pointer tw-block

      /* mobile menu item view */
      tw-py-2 tw-text-base tw-block

      /* desktop menu item view*/
      lg:tw-px-3 xl:tw-px-4 lg:tw-text-sm lg:tw-py-1 lg:tw-inline-block;

      &--open {
        @apply tw-text-blue-800;

        .header__menu-icon {
          @apply tw-rotate-180;
        }
      }

      &--dropdown {
        @apply tw-w-full lg:tw-w-auto tw-flex tw-items-center;
      }
    }

    &__submenu {
      @apply  tw-bg-white tw-w-auto tw-py-2 lg:tw-mt-3

      /* mobile submenu item view */
      tw-relative

      /* desktop submenu item view */
      lg:tw-absolute lg:tw-shadow-3xl lg:tw-rounded-xl;
    }

    &__submenu-item {
      @apply tw-block tw-px-4 tw-py-[6px] tw-text-neutral-900 hover:tw-text-blue-500 tw-whitespace-nowrap tw-text-md/6;
    }

    &__menu-hamburger {
      @apply tw-h-5 tw-w-5 tw-relative;

      .header__menu-hamburger-inner,
      .header__menu-hamburger-inner::before,
      .header__menu-hamburger-inner::after {
        @apply tw-bg-neutral-900 tw-rounded-sm tw-content-[''] tw-absolute tw-w-5 tw-h-[2px] tw-mt-[9px] tw-left-0 tw-top-0 tw-transition tw-duration-300 tw-ease-in-out;
      }

      .header__menu-hamburger-inner::before {
        @apply tw-mt-[-7px]
      }

      .header__menu-hamburger-inner::after {
        @apply tw-mt-[7px]
      }

      &.active {
        .header__menu-hamburger-inner {
          @apply tw-bg-transparent;
        }

        .header__menu-hamburger-inner::before {
          @apply tw-mt-0 tw-rotate-45;
        }

        .header__menu-hamburger-inner::after {
          @apply tw-mt-0 tw-rotate-[-45deg];
        }
      }
    }

    &__menu-mobile-actions {
      @apply tw-flex lg:tw-hidden tw-items-center tw-mb-7;
    }

    &__menu-mobile-auth {
      @apply lg:tw-hidden tw-flex tw-flex-col tw-bg-neutral-0 tw-w-full tw-pt-4;
    }
  }
}
