@layer components {
  .service-steps {
    @apply tw-mb-[80px] lg:tw-mb-[156px];

    &__inner {
      @apply tw-bg-blue-50 tw-w-full tw-rounded-2xl lg:tw-rounded-3xl tw-p-4 tw-pb-6 lg:tw-px-10 lg:tw-pt-8 lg:tw-pb-[48px] tw-text-neutral-900;
    }

    &__text {
      @apply tw-leading-snug tw-text-base tw-mb-6 tw-text-neutral-900 tw-mb-6 lg:tw-mb-[48px] tw-max-w-sm;
    }

    &__list {
      @apply tw-flex tw-flex-wrap;
    }

    &__item {
      @apply tw-w-1/2 lg:tw-w-auto tw-mb-7 lg:tw-mb-0 tw-pr-3 lg:tw-pr-[45px];

      &:last-of-type {
        @apply tw-pr-0;
      }
    }

    &__item-content {
      @apply tw-flex tw-mb-[14px] tw-items-center;
    }

    &__item-num {
      @apply tw-w-9 tw-min-w-[36px] tw-h-9 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-border-solid tw-border-[3px] tw-border-neutral-900 tw-text-neutral-900 tw-mr-2 tw-font-bold;
    }

    &__item-title {
      @apply tw-text-base tw-font-bold;
    }
  }
}