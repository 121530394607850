@layer components {
  .about-us-section {
    @apply tw-mb-[64px] lg:tw-mb-[156px];

    &__inner {
      @apply tw-flex tw-flex-col lg:tw-flex-row;
    }

    &__picture {
      @apply tw-w-full lg:tw-max-w-[370px] tw-mb-4 lg:tw-mb-0;
    }

    &__img {
      @apply tw-object-cover tw-object-center tw-rounded-2xl lg:tw-rounded-3xl;
    }

    &__content {
      @apply tw-w-full tw-rounded-2xl lg:tw-rounded-3xl tw-p-4 lg:tw-py-8 lg:tw-px-10 tw-text-neutral-900 tw-text-md lg:tw-text-base;

      &--orange {
        @apply tw-bg-orange-50;
      }

      &--blue {
        @apply tw-bg-blue-50;
      }
    }

    &__content-title {
      @apply tw-mb-8 lg:tw-mb-6;
    }

    &__text {
      @apply tw-max-w-sm tw-leading-snug lg:tw-leading-9;
    }
  }
}