@layer components {
  .assistance {
    @apply tw-mb-[64px] lg:tw-mb-[156px];

    &__inner {
      @apply tw-flex tw-flex-col-reverse lg:tw-flex-row;
    }

    &__picture {
      @apply tw-w-full lg:tw-max-w-[370px] tw-ml-0 tw-mb-4 lg:tw-mb-0 lg:tw-ml-8;
    }

    &__img {
      @apply tw-object-cover tw-object-center tw-rounded-2xl lg:tw-rounded-3xl tw-aspect-square tw-max-h-[176px] lg:tw-max-h-full;
    }

    &__content {
      @apply tw-bg-blue-50 tw-w-full tw-rounded-2xl lg:tw-rounded-3xl tw-p-4 tw-pb-6 lg:tw-pt-8 lg:tw-pb-[48px] lg:tw-px-10 tw-text-neutral-900 tw-text-base;
    }

    &__content-title {
      @apply tw-mb-4 lg:tw-mb-6 tw-leading-8 lg:tw-leading-snug;
    }

    &__content-link {
      @apply tw-text-base tw-mt-8;
    }

    &__text {
      @apply tw-max-w-[480px] tw-text-md lg:tw-text-base tw-leading-6 lg:tw-leading-9 tw-mb-4 lg:tw-mb-8;

      &:last-of-type {
        @apply tw-mb-0
      }
    }
  }
}
