.consultations-calendar {
  @apply tw-flex tw-flex-col lg:tw-flex-row;

  &__half {
    @apply tw-flex-1 tw-flex tw-flex-col;
  }

  &__header {
    @apply tw-font-bold tw-mb-4 tw-text-[20px]/[20px] lg:tw-text-[24px]/[40px] tw-text-neutral-900;
  }

  &__calendar-wrapper {
    @apply lg:tw-max-w-[320px] tw-mb-4 lg:tw-mb-8 tw-rounded tw-border tw-border-gray-100 tw-bg-neutral-0;
  }

  &__time-zone-select-wrapper {
    @apply tw-m-3 tw-relative;

    &::after {
      @apply tw-absolute tw-right-[20px] tw-top-1/2 tw-translate-y-[-50%] tw-w-0 tw-h-0 tw-border-solid tw-border-[3px] tw-border-transparent tw-border-t-[3px] tw-border-t-black tw-border-b-0 tw-content-[''];
    }
  }

  &__time-zone-select {
    @apply tw-appearance-none tw-p-3 tw-w-full tw-rounded tw-border tw-border-solid tw-border-gray-100 tw-bg-neutral-0 tw-text-[14px]/[20px];
  }

  &__note {
    @apply lg:tw-max-w-[320px] tw-text-[14px]/[22px] lg:tw-text-[18px]/[22px] tw-text-neutral-900 tw-mb-[48px] lg:tw-mb-4;
  }

  &__time-selector {
    @apply tw-rounded tw-border tw-border-gray-100 tw-bg-neutral-0;
  }

  &__time-selector-header {
    @apply tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-start lg:tw-items-center tw-p-4 lg:tw-px-6 lg:tw-py-0 tw-border-solid tw-border-0 tw-border-b tw-border-gray-100 tw-text-[18px]/[20px] tw-text-neutral-900 tw-font-bold;
  }

  &__time-selector-date {
    @apply tw-text-[14px]/[16px] lg:tw-text-[18px]/[20px];
  }

  &__time-selector-toggle {
    @apply tw-flex tw-text-[14px]/[16px] lg:tw-text-[18px]/[20px] tw-p-0 lg:tw-py-5 lg:tw-px-4 tw-mt-2 lg:tw-mt-0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      border: none;
      appearance: none;
    }
  }

  &__time-selector-slider {
    @apply tw-relative tw-w-[26px] tw-h-[16px] lg:tw-w-[32px] lg:tw-h-[20px] tw-mr-2 tw-bg-gray-300 tw-rounded-full tw-transition tw-duration-300 tw-cursor-pointer;

    &:before {
      @apply tw-absolute tw-content-[''] tw-h-[12px] tw-w-[12px] lg:tw-h-[16px] lg:tw-w-[16px] tw-left-[2px] tw-bottom-1/2 tw-translate-y-[6px] lg:tw-translate-y-[8px] tw-bg-neutral-0 tw-rounded-full tw-transition tw-duration-300;

      input:checked + & {
        @apply tw-translate-x-[10px] lg:tw-translate-x-[12px];
      }
    }

    input:checked + & {
      @apply tw-bg-blue-500;
    }
  }

  &__select {
    @apply tw-flex-1 tw-flex tw-flex-col tw-h-[320px] lg:tw-h-[400px] tw-overflow-y-auto;
  }

  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }

  &__name-mounth {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #303030;
  }

  &__prev-mounth,
  &__next-mounth {
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    color: transparent;
    background-size: 100% auto;
    display: block;
    background-image: url("../images/icons/icon-calendar-arrow.svg");
    cursor: pointer;
  }

  &__prev-mounth {
    transform: rotate(180deg);
  }

  &__calendar-day {
    @apply tw-flex tw-border-solid tw-border-0 tw-border-b tw-border-gray-100;
    margin: 15px 0 0;
  }

  &__item-time {
    @apply tw-flex tw-justify-between tw-items-center tw-border-solid tw-border-0 tw-border-b tw-border-gray-100 tw-pl-4 lg:tw-px-6 tw-text-[14px]/[16px] lg:tw-text-[18px]/[20px] tw-text-neutral-900;
  }

  &__item-time-available-button {
    @apply tw-px-4 tw-py-5 lg:tw-py-6 tw-font-bold tw-text-blue-500 hover:tw-text-blue-700 tw-min-w-[80px];
  }

  &__consultation-days {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px 0 10px;
  }

  &__day {
    width: 14.28%;
    margin: 5px 0 0;
    height: 32px;
    text-align: center;
  }

  &__item-day-header {
    @apply tw-text-gray-500 tw-text-[9px]/[16px] lg:tw-text-[12px]/[20px] tw-uppercase tw-flex-1 tw-text-center tw-mb-1;
  }

  &__item-day {
    @apply tw-relative tw-inline-block tw-rounded-full tw-w-6 tw-h-6 lg:tw-w-8 lg:tw-h-8 tw-text-neutral-800 tw-text-[12px]/[16px] lg:tw-text-[15px]/[20px] tw-font-bold tw-py-1 lg:tw-py-[6px];

    &--prev-mounth-day {
      color: #aaa;
    }

    &--working {
      background: #e8f1fd;
      @apply tw-text-blue-500;
    }

    &.blue {
      @apply tw-text-blue-500;

      &::before {
        @apply tw-bg-blue-500;
      }

      &:hover {
        @apply tw-text-blue-700;

        &::before {
          @apply tw-bg-blue-700;
        }
      }
    }

    &--current {
      @apply tw-text-neutral-0 tw-bg-blue-700;

      &::before {
        display: none;
      }

      &:hover {
        @apply tw-bg-blue-800;
      }
    }

    &--today {
      @apply tw-text-orange-600;
    }
  }
}
