@layer components {
  .service-header {
    @apply tw-mt-6 lg:tw-mt-[80px] tw-mb-[64px] lg:tw-mb-[156px];

    &__inner {
      @apply tw-flex tw-justify-center lg:tw-justify-between;
    }

    &__content {
      @apply tw-flex tw-flex-col tw-max-w-[515px] tw-text-center lg:tw-text-left;
    }

    &__content-text {
      @apply tw-mb-6 tw-font-bold tw-text-xl tw-text-gray-500;
    }

    &__btns {
      @apply tw-flex tw-mb-8 lg:tw-mb-[64px];

      .btn-lg {
        @apply tw-w-full lg:tw-w-auto tw-whitespace-nowrap;
      }
    }

    &__picture {
      @apply tw-hidden lg:tw-block tw-w-full lg:tw-max-w-[344px] tw-ml-0 tw-mb-4 lg:tw-mb-0 lg:tw-ml-8;
    }

    &__img {
      @apply tw-object-cover tw-object-center tw-rounded-2xl lg:tw-rounded-3xl tw-aspect-auto tw-h-full;
    }
    &__reviews {
      @apply tw-flex tw-flex-col tw-justify-center tw-items-center lg:tw-items-start;
    }

    &__reviews-title {
      @apply tw-mb-4 tw-text-[22px] lg:tw-text-xl tw-font-bold;
    }

    &__reviews-count {
      @apply tw-flex tw-items-center tw-mb-4;
    }

    &__reviews-stars {
      @apply tw-text-base tw-tracking-[3px] tw-text-amber-400;
    }

    &__reviews-text {
      @apply tw-text-base tw-font-bold tw-ml-4;
    }

    &__social-reviews {
      @apply tw-flex tw-w-full tw-items-center;
    }
  }
}